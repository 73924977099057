.blockquest {
  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    margin-bottom: 3rem;
  }
  p {
    font-weight: normal;
    font-size: 2.4rem;
  }
}
.resultblock {
  display: flex;
  justify-content: space-between;
  .error {
    input {
      border: 1px solid red !important;
    }
  }
  .mailform {
    max-width: 30%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .somep {
      font-size: 1.4rem;
      font-weight: normal;
      margin-top: 5.8rem;
    }
    input {
      max-width: calc(100% - 2rem);
    }
  }
}
.money {
  max-width: 60%;
  background: #f7f5ff;
  border-radius: 20px;
  padding: 4.5rem 4rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .flexmoney {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 47%;
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      .checkblock {
        width: 24px;
        height: 24px;
        min-width: 24px;
        background: #513ccc;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.9rem;
      }
      p {
        font-size: 1.6rem;
        line-height: auto;
      }
    }
  }
  > div > p {
    font-size: 1.4rem;
    line-height: 17px;
    /* identical to box height */

    color: #b9b9b9;
  }
  .endprice {
    margin-top: 3rem;
    color: #513ccc;
    font-weight: bold;
    font-size: 3.6rem;
    margin-bottom: 0.6rem;
  }
}
