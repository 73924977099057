.bluebutton {
  max-width: 20.9rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #513ccc;
  width: 100%;
  border-radius: 7rem;
  line-height: 2.4rem;
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  svg {
    margin-left: 1.3rem;
  }
}
