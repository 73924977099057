@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MainPage_container__VOdz4{display:flex;align-items:center;height:calc(100vh - 14.8rem)}.MainPage_container__VOdz4 h1{font-weight:bold;font-size:5rem;margin-bottom:5rem;margin-top:1.5rem}.MainPage_container__VOdz4 p{font-size:3.6rem;max-width:90rem}

.next_bluebutton__gXJ9Q{max-width:20.9rem;height:6rem;display:flex;justify-content:center;align-items:center;background-color:#513ccc;width:100%;border-radius:7rem;line-height:2.4rem;font-size:1.4rem;color:white;font-weight:bold;cursor:pointer}.next_bluebutton__gXJ9Q svg{margin-left:1.3rem}

.Back_backbutton__1ZXx0{width:5.6rem;height:5.6rem;border:solid 2px #513ccc;border-radius:100%;align-items:center;justify-content:center;display:flex;transition:all 0.3s ease;cursor:pointer}.Back_backbutton__1ZXx0:hover{background-color:#513ccc}.Back_backbutton__1ZXx0:hover path{fill:white}

.FooterBlock_downblock__1nZeH{display:flex;justify-content:space-between;align-items:center}.FooterBlock_downblock__1nZeH .FooterBlock_buttonsblock__1CDgj{display:flex;width:40%}.FooterBlock_downblock__1nZeH .FooterBlock_priceBlock__2Qd3X{text-align:right}.FooterBlock_downblock__1nZeH .FooterBlock_priceBlock__2Qd3X div{font-size:3.6rem;font-weight:bold;color:#513ccc}.FooterBlock_downblock__1nZeH .FooterBlock_priceBlock__2Qd3X p{font-size:1.4rem;line-height:1.7rem;color:#b9b9b9}

.QuestionPage_container___lkmL{display:flex;align-items:center;height:calc(100vh - 14.8rem)}.QuestionPage_container___lkmL .QuestionPage_blockquest__2NsiV .QuestionPage_numberquestion__1jyC-{color:#b9b9b9;font-size:1.8rem;line-height:2.2rem;margin-bottom:2.2rem}.QuestionPage_container___lkmL .QuestionPage_blockquest__2NsiV h1{font-size:3.6rem;line-height:4rem;margin-bottom:5rem}

.RadioButton_ContainerRadio__1RrL6{margin-bottom:2.7rem}.RadioButton_ContainerRadio__1RrL6>div{display:flex;align-items:center;margin-bottom:1.7rem}.RadioButton_ContainerRadio__1RrL6>div .RadioButton_radio__2aMvN{margin:0px;border:1px solid #d5d8df;width:23px;height:23px;background:#fcfdff;outline:none;border-radius:100%;display:flex;justify-content:center;align-items:center}.RadioButton_ContainerRadio__1RrL6>div .RadioButton_radio__2aMvN.RadioButton_check__1uA1i{background-color:#513ccc !important;border:1px solid #513ccc !important}.RadioButton_ContainerRadio__1RrL6>div .RadioButton_radio__2aMvN.RadioButton_check__1uA1i::after{content:"";display:block;width:12px;height:12px;background-color:white;border-radius:100%}.RadioButton_ContainerRadio__1RrL6>div .RadioButton_radio__2aMvN:hover{background:#fcfdff;border:1px solid #513ccc !important}.RadioButton_ContainerRadio__1RrL6>div p{font-size:1.6rem;margin-left:0.9rem;font-weight:normal;line-height:auto}

.inputsText_mainInputsText__N6n88{margin-bottom:2.7rem}.inputsText_mainInputsText__N6n88 p{font-size:1.4rem;font-weight:normal;color:black;margin-bottom:1.7rem}.inputsText_mainInputsText__N6n88 input{border-radius:70rem;border:1px solid #b9b9b9;max-width:36rem;width:100%;height:6rem;padding-left:2rem;font-weight:normal;font-size:1.8rem;outline:none}.inputsText_mainInputsText__N6n88 input:hover{border:1px solid #513ccc !important}.inputsText_mainInputsText__N6n88 input:focus{border:1px solid #513ccc !important}

.CheckedBlock_ContainerRadio__3hRPj>div{display:flex;align-items:center;margin-bottom:1.7rem}.CheckedBlock_ContainerRadio__3hRPj>div .CheckedBlock_input__1mV7-{margin:0px;border:1px solid #d5d8df;width:23px;height:23px;background:#fcfdff;outline:none;border-radius:100%;display:flex;justify-content:center;align-items:center;cursor:pointer}.CheckedBlock_ContainerRadio__3hRPj>div .CheckedBlock_input__1mV7-.CheckedBlock_check__3w281{background-color:#513ccc !important;border:1px solid #513ccc !important}.CheckedBlock_ContainerRadio__3hRPj>div .CheckedBlock_input__1mV7-:hover{background:#fcfdff;border:1px solid #513ccc !important}.CheckedBlock_ContainerRadio__3hRPj>div p{font-size:1.6rem;margin-left:0.9rem;font-weight:normal;line-height:auto}

.InputsNumber_InputsNumber__19P5L p{font-size:1.6rem;margin-bottom:2rem}.InputsNumber_InputsNumber__19P5L .InputsNumber_minus__3_Cov{position:absolute;height:100%;left:0px;width:6rem;display:flex;align-items:center;justify-content:center;top:0px;cursor:pointer}.InputsNumber_InputsNumber__19P5L .InputsNumber_plus__ex_hg{cursor:pointer;position:absolute;height:100%;right:0px;width:6rem;display:flex;align-items:center;justify-content:center;top:0px}.InputsNumber_InputsNumber__19P5L>div{-webkit-user-select:none;-ms-user-select:none;user-select:none;display:flex;align-items:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:6rem;padding:0px 6rem;border:1px solid #b9b9b9;box-sizing:border-box;border-radius:70px;font-size:1.6rem;font-weight:normal;position:absolute}

.ThanksPage_blockquest__3qV_g h1{font-size:3.6rem;line-height:4rem;margin-bottom:3rem}.ThanksPage_blockquest__3qV_g p{font-weight:normal;font-size:2.4rem}.ThanksPage_resultblock__20nFE{display:flex;justify-content:space-between}.ThanksPage_resultblock__20nFE .ThanksPage_error__l1cL_ input{border:1px solid red !important}.ThanksPage_resultblock__20nFE .ThanksPage_mailform__1bHJX{max-width:30%}.ThanksPage_resultblock__20nFE .ThanksPage_mailform__1bHJX .ThanksPage_somep__2Vh9U{font-size:1.4rem;font-weight:normal;margin-top:5.8rem}.ThanksPage_resultblock__20nFE .ThanksPage_mailform__1bHJX input{max-width:calc(100% - 2rem)}.ThanksPage_money__ZF6Fs{max-width:60%;background:#f7f5ff;border-radius:20px;padding:4.5rem 4rem;text-align:left;display:flex;flex-direction:column;justify-content:space-between}.ThanksPage_money__ZF6Fs .ThanksPage_flexmoney__19UHc{display:flex;width:100%;flex-wrap:wrap;justify-content:space-between}.ThanksPage_money__ZF6Fs .ThanksPage_flexmoney__19UHc>div{width:47%;display:flex;align-items:center;margin-bottom:2rem}.ThanksPage_money__ZF6Fs .ThanksPage_flexmoney__19UHc>div .ThanksPage_checkblock__3u7fy{width:24px;height:24px;min-width:24px;background:#513ccc;border-radius:100%;display:flex;align-items:center;justify-content:center;margin-right:0.9rem}.ThanksPage_money__ZF6Fs .ThanksPage_flexmoney__19UHc>div p{font-size:1.6rem;line-height:auto}.ThanksPage_money__ZF6Fs>div>p{font-size:1.4rem;line-height:17px;color:#b9b9b9}.ThanksPage_money__ZF6Fs .ThanksPage_endprice__2FGgN{margin-top:3rem;color:#513ccc;font-weight:bold;font-size:3.6rem;margin-bottom:0.6rem}

.LoginPage_container__3RtRX{display:flex;align-items:center;height:calc(100vh - 14.8rem)}.LoginPage_container__3RtRX .LoginPage_center__17AbV{text-align:center}.LoginPage_container__3RtRX .LoginPage_center__17AbV>div{margin:3rem auto 0rem}.LoginPage_container__3RtRX .LoginPage_center__17AbV h1{font-weight:bold;font-size:3rem;margin-bottom:3rem;margin-top:1.5rem}.LoginPage_container__3RtRX .LoginPage_center__17AbV p{margin-top:2rem;font-size:2.6rem}

.Header_ModalP__29w6Y{font-size:2.5rem;font-family:"Montserrat", sans-serif;font-weight:400;margin-bottom:2rem}.Header_ModalButtons__1_BsD{display:flex;justify-content:space-between;align-items:center}.Header_ModalButtons__1_BsD>div{border:2px solid #513ccc;width:12rem;border-radius:7rem;line-height:3.5rem;text-align:center;font-size:2rem;cursor:pointer;transition:all 0.3s ease}.Header_ModalButtons__1_BsD>div:hover{background-color:#513ccc;color:white}.Header_headercontainer__1fjym{display:flex;justify-content:space-between;padding:4rem 4rem 0rem 4rem;margin-bottom:4rem}.Header_headercontainer__1fjym .Header_left__14yk9 img{max-width:6rem;height:auto;width:100%}.Header_headercontainer__1fjym .Header_repeat__2oMOe{display:flex;align-items:center}.Header_headercontainer__1fjym .Header_repeat__2oMOe>div{display:block;transition:all 0.3s ease;width:5.8rem;height:5.8rem;border:solid 2px #513ccc;border-radius:100%;display:flex;justify-content:center;align-items:center}.Header_headercontainer__1fjym .Header_repeat__2oMOe>div:hover{background-color:#513ccc}.Header_headercontainer__1fjym .Header_repeat__2oMOe>div:hover path{stroke:white}.Header_headercontainer__1fjym .Header_repeat__2oMOe p{font-weight:600;line-height:2.4rem;font-size:1.4rem;color:#513ccc;margin-right:1.9rem}

html {
  font-size: 10px;
}
body {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
}

p {
  margin: 0px;
}

.Error {
  font-size: 2rem;
  color: #513ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}
.Success {
  font-size: 2rem;
  color: #513ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.container {
  max-width: 125rem;
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  max-height: 60rem;
  height: calc(100% - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10rem;
}

@media (max-width: 991.98px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 760.98px) {
  html {
    font-size: 6px;
  }
}

@media (max-width: 576.98px) {
  html {
    font-size: 4px;
  }
}

