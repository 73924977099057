.downblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttonsblock {
    display: flex;
    width: 40%;
  }
  .priceBlock {
    text-align: right;
    div {
      font-size: 3.6rem;
      font-weight: bold;
      color: #513ccc;
    }
    p {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #b9b9b9;
    }
  }
}
