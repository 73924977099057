.InputsNumber {
  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .minus {
    position: absolute;
    height: 100%;
    left: 0px;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    cursor: pointer;
  }
  .plus {
    cursor: pointer;
    position: absolute;
    height: 100%;
    right: 0px;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
  }
  > div {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;

    width: fit-content;
    height: 6rem;
    padding: 0px 6rem;
    border: 1px solid #b9b9b9;
    box-sizing: border-box;
    border-radius: 70px;
    font-size: 1.6rem;
    font-weight: normal;
    position: absolute;
  }
}
