.mainInputsText {
  margin-bottom: 2.7rem;
  p {
    font-size: 1.4rem;
    font-weight: normal;
    color: black;
    margin-bottom: 1.7rem;
  }
  input {
    border-radius: 70rem;
    border: 1px solid #b9b9b9;
    max-width: 36rem;
    width: 100%;
    height: 6rem;
    padding-left: 2rem;
    font-weight: normal;
    font-size: 1.8rem;
    outline: none;
    &:hover {
      border: 1px solid #513ccc !important;
    }
    &:focus {
      border: 1px solid #513ccc !important;
    }
  }
}
