.container {
  display: flex;
  align-items: center;
  height: calc(100vh - 14.8rem);
  .center {
    text-align: center;

    > div {
      margin: 3rem auto 0rem;
    }
    h1 {
      font-weight: bold;
      font-size: 3rem;
      margin-bottom: 3rem;
      margin-top: 1.5rem;
    }

    p {
      margin-top: 2rem;
      font-size: 2.6rem;
      // max-width: 90rem;
    }
  }
}
