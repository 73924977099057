.ModalP {
  font-size: 2.5rem;
  // color: #513ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-bottom: 2rem;
}
.ModalButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    border: 2px solid #513ccc;
    width: 12rem;
    border-radius: 7rem;
    line-height: 3.5rem;
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #513ccc;
      color: white;
    }
  }
}
.headercontainer {
  display: flex;
  justify-content: space-between;
  padding: 4rem 4rem 0rem 4rem;
  margin-bottom: 4rem;

  .left {
    img {
      max-width: 6rem;
      height: auto;
      width: 100%;
    }
  }
  .repeat {
    display: flex;
    align-items: center;
    > div {
      &:hover {
        background-color: #513ccc;
        path {
          stroke: white;
        }
      }
      display: block;
      transition: all 0.3s ease;
      width: 5.8rem;
      height: 5.8rem;
      border: solid 2px #513ccc;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-weight: 600;
      line-height: 2.4rem;
      font-size: 1.4rem;
      color: #513ccc;
      margin-right: 1.9rem;
    }
  }
}
