.backbutton {
  width: 5.6rem;
  height: 5.6rem;
  border: solid 2px #513ccc;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #513ccc;
    path {
      fill: white;
    }
  }
}
