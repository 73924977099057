.ContainerRadio {
  margin-bottom: 2.7rem;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1.7rem;

    .radio {
      margin: 0px;
      border: 1px solid #d5d8df;
      width: 23px;
      height: 23px;
      background: #fcfdff;
      outline: none;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.check {
        background-color: #513ccc !important;
        border: 1px solid #513ccc !important;
        &::after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background-color: white;
          border-radius: 100%;
        }
      }
      &:hover {
        background: #fcfdff;
        border: 1px solid #513ccc !important;
      }
    }
    p {
      font-size: 1.6rem;
      margin-left: 0.9rem;
      font-weight: normal;
      line-height: auto;
    }
  }
}
