.container {
  display: flex;
  align-items: center;
  height: calc(100vh - 14.8rem);
  h1 {
    font-weight: bold;
    font-size: 5rem;
    margin-bottom: 5rem;
    margin-top: 1.5rem;
  }

  p {
    font-size: 3.6rem;
    max-width: 90rem;
  }
}
