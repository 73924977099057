@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500&display=swap");
html {
  font-size: 10px;
}
body {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none; /* Отменяем подчеркивание у ссылки */
}

p {
  margin: 0px;
}

.Error {
  font-size: 2rem;
  color: #513ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}
.Success {
  font-size: 2rem;
  color: #513ccc;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.container {
  max-width: 125rem;
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  max-height: 60rem;
  height: calc(100% - 10rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 10rem;
}

@media (max-width: 991.98px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 760.98px) {
  html {
    font-size: 6px;
  }
}

@media (max-width: 576.98px) {
  html {
    font-size: 4px;
  }
}
