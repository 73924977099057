.container {
  display: flex;
  align-items: center;
  height: calc(100vh - 14.8rem);

  .blockquest {
    .numberquestion {
      color: #b9b9b9;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 2.2rem;
    }
    h1 {
      font-size: 3.6rem;
      line-height: 4rem;
      margin-bottom: 5rem;
    }
  }
}
